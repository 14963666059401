<template>
  <div class="gal">
    <div v-if="videos != ''">
      <div class="row">
        <div class="video-player-col"
             :class="colSize"
             v-for="(video, index) in videos"
             :key="index">

          <div class="content-wrapper text-center">
            <div class="overlay-actions">
              <!-- Share Feature -->
              <!-- <div class="overlay-share"
                   v-if="shareFeature === true">
                <i class="fas fa-share fa-2x share-icon"
                   @click="showShareDialog(video.item)">
                </i>
              </div> -->

              <!-- Delete Feature -->
              <div class="overlay-delete"
                   v-if="deleteFeature === true">
                <i class="fas fa-trash-alt delete-icon"
                   @click="deleteVideo(video)">
                </i>
              </div>

              <!-- Report Feature -->
              <div class="overlay-report"
                   v-if="reportFeature === true">
                <i class="fas fa-flag report-icon"
                   @click="reportVideo(video)"
                   title="Report Video">
                </i>
              </div>

              <!-- Download Feature -->
              <div class="overlay-download">
                  <i class="fas fa-download fa-2x download-icon"
                     @click="downloadVideo(video)"></i>
              </div>
            </div>
            
            <!-- Video -->
            <vue-plyr :class="border ? 'video-border' : '' ">
              <video class="video-player"
                     :src="video.item.url"
                     :style="{height: `${vpHeight}px`}">
                <source :src="video.item.url"
                        type="video/mp4"
                        size="720">
                <source :src="video.item.url"
                        type="video/mp4"
                        size="1080">
              </video>
            </vue-plyr>

            <!-- User Image -->
            <div class="overlay-user-image"
                 v-if="userImage === true">
              <p class="user-name mb-0">
                <img :src="video.item.profile_img_url"
                     alt="user-profile" />
                {{ video.item.nickname }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <slot name="load"></slot>
    </div>

    <!-- Empty -->
    <div class="row" v-else>
      <div class="col" v-if="!downloading">
        <p class="default-text">No videos available</p>
      </div>
    </div>

    <!-- Modal -->
    <modals-container/>

    <!-- Dialog -->
    <v-dialog />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  const ReportContent = () => import('@/components/modals/ReportContent.vue');
  
  // import { mapActions, mapGetters } from 'vuex';
  // const ShareContent = () => import('@/components/modals/ShareContent.vue');

  export default {
    name  : 'VideoGallery',
    props : {
      border        : Boolean,
      colVideo      : Number,
      deleteFeature : Boolean,
      reportFeature : Boolean,
      shareFeature  : Boolean,
      userImage     : Boolean,
      videos        : Array,
      downloading   : Boolean,
    },
    data() {
      return {
        colWidth : 0,
        vpHeight : 0,
      };
    },
    updated() {
      this.getColumnWidth();
    },
    mounted() {
      this.getColumnWidth();

      // Get Classrooms
      // this.getUserClassrooms();
    },
    computed : {
      ...mapGetters({
        user : 'user/user',

        // userClassrooms : 'classroom/userClassrooms',
      }),
      colSize() {
        return 'col-' + this.colVideo;
      },
    },
    watch : {
      colWidth : function () {
        this.vpHeight = this.colWidth > 0 ? this.colWidth * 0.75 : 0;
      },
    },
    methods : {

      // ...mapActions({
      //   getUserClassrooms : 'classroom/getUserClassrooms',
      // }),

      /**
       * Share Dialog
       *
       * @param video Video data
       */
      // showShareDialog(video) {

      //   if (this.userClassrooms) {
      //     this.$modal.show(ShareContent, {
      //       mediaData : video,
      //       type      : 'video',
      //     }, {
      //       height   : 'auto',
      //       adaptive : true,
      //     });
      //   } else {
      //     this.$notify({
      //       group : 'notif',
      //       type  : 'error',
      //       text  : 'Unable to share video. Please join any classroom first.',
      //     });
      //   }

      // },

      /**
       * Delete Video
       *
       * @param video Video data
       */
      deleteVideo(video) {
        this.$modal.show('dialog', {
          title   : 'Delete Video',
          text    : 'Are you sure you want to delete this video?',
          buttons : [ {
            title   : 'Yes',
            handler : () => {
              this.$emit('loadingchange', true);
              this.$http.delete('api/media/ucc/delete', {
                data : {
                  'userId' : this.user.user_id,
                  'id'     : video.item.id,
                },
              }).then(() => {
                this.$notify({
                  group : 'notif',
                  title : 'Success',
                  type  : 'success',
                  text  : 'Deleted video successfully!',
                });
                this.$emit('loadingchange', false);
                this.$emit('update-user-contents');
                this.$modal.hide('dialog');
              }).catch(() => {
                this.$notify({
                  group : 'notif',
                  title : 'Failed',
                  type  : 'error',
                  text  : 'OOPS! Something went wrong!',
                });
              });
            },
          }, {
            title : 'No',
          } ],
        });
      },

      /**
       * Report Video
       * @param video
       */
      reportVideo(video) {
        this.$modal.show(ReportContent, {
          mediaData : video,
          type      : 'video',
        }, {
          height   : 'auto',
          adaptive : true,
        });
      },

      /**
       * Get Column's Width
       */
      getColumnWidth() {
        this.$nextTick(() => {
          const $colId = this.$el.getElementsByClassName('video-player-col')[0];
          if ($colId)
            this.colWidth = $colId.clientWidth;
        });
      },

      /**
       * Download Video
       */
      downloadVideo(video) {
        this.$emit('loadingchange', true);
        this.$http.get('/api/ucc/downloadUrl', {
          params : {
            uccId : video.item.id,
          },
        }).then((res) => {
          const xhr = new XMLHttpRequest();
          xhr.open("GET", res.data.url, true);
          xhr.responseType = 'blob';
          xhr.onload = () => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(xhr.response);
            document.body.appendChild(a);
            a.download = video.item.filename;
            a.href = url;
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            this.$emit('loadingchange', false);
          };
          xhr.send();
        })
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/common/video-gallery";
</style>
